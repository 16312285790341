export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || "Barbarossa.ai";

export const HOME_DOMAIN = `https://${process.env.NEXT_PUBLIC_APP_DOMAIN}`;

export const DUB_LOGO = "https://assets.barbarossa.ai/logo.png";
export const DUB_WORDMARK = "https://assets.barbarossa.ai/wordmark.png";
export const DUB_THUMBNAIL = "https://assets.barbarossa.ai/thumbnail.jpg";

export const SHORT_DOMAIN =
  process.env.NEXT_PUBLIC_APP_SHORT_DOMAIN || "barbarossa.sh";

export const APP_HOSTNAMES = new Set([
    `app.${process.env.NEXT_PUBLIC_APP_DOMAIN}`,
    `preview.${process.env.NEXT_PUBLIC_APP_DOMAIN}`,
    "localhost:8888",
    "localhost",
]);

export const APP_DOMAIN =
process.env.NEXT_PUBLIC_VERCEL_ENV === "production"
? `https://app.${process.env.NEXT_PUBLIC_APP_DOMAIN}`
: process.env.NEXT_PUBLIC_VERCEL_ENV === "preview"
    ? `https://preview.${process.env.NEXT_PUBLIC_APP_DOMAIN}`
    : "http://localhost:8888";


export const ADMIN_HOSTNAMES = new Set([
    `admin.${process.env.NEXT_PUBLIC_APP_DOMAIN}`,
    "admin.localhost:8888",
]);
