"use client";

import { LucideIcon } from "lucide-react";
import { ComponentType, SVGProps } from "react";

// loaders
export * from "./loading-spinner";
export * from "./crazy-spinner";
export * from "./loading-circle";
export * from "./magic";
export * from "./globe";
export * from "./eye";
export * from "./eye-slash";
export * from "./input-password";
export * from "./layout-sidebar";
export * from "./logo";


// brand logos
export * from "./github";
export * from "./google";


export type Icon = LucideIcon | ComponentType<SVGProps<SVGSVGElement>>;
